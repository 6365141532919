import React from 'react';
import { TextArea } from '@dazn/acc-sdk-design-system';
import { InputRows } from '../inputRows';
import surveyData from '../../nps_files/survey.json';
import * as styles from './styles';

interface Score {
  value: number;
  labelText: string;
}

interface SurveyAnswers {
  surveyState: {
    stage: number;
    score?: number;
    feedback?: string;
  };
  onSetScore: React.Dispatch<React.SetStateAction<number | undefined>>;
  onSetFeedback: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const SurveyAnswers: React.FC<SurveyAnswers> = ({ surveyState, onSetScore, onSetFeedback }) => {
  const { stage, score, feedback } = surveyState;
  const surveyScores: Score[] = surveyData.scores;

  if (stage === 1) {
    return (
      <>
        {surveyScores.map(({ value, labelText }, index) => {
          return (
            <InputRows
              key={`key${index}`}
              name={`npsScore${value}`}
              id={`nps${value}`}
              value={String(value)}
              checked={score === value}
              onChange={({ target }) => onSetScore(parseInt(target?.value))}
              label={labelText}
            />
          );
        })}
      </>
    );
  }

  return (
    <TextArea
      aria-label="survey-feedback"
      placeholder="Start Typing&hellip;"
      css={styles.surveyFeedback}
      value={feedback || ''}
      onChange={newValue => onSetFeedback(newValue)}
    />
  );
};
