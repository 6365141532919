export const INITIALISE_QUERY = `
  query getPortalInitialInformation {
    userManagement {
      currentUser {
        avatar
        permissionGrants {
          name
        }
        isExternal
      }
    }
  }
`;

export const SYSTEM_FLAG_QUERY = `
query getAllFlags {
  accSystemFlags {
    flags(onlyEnabled: true) {
      name
    }
    __typename
  }
}
`;
