import { css, keyframes } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

import { NAV_TRANSITION_DURATION } from '../../constants';

const animations = {
  flex: keyframes`
    from {
      flex-direction: row;
    }
    to {
      flex-direction: column;
    }
  `,
  opacity: keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
  iconSpacing: keyframes`
    to {
      margin-bottom: ${tokens.spacingPx.medium};
    }
  `,
};

export const base = (isMinimized: boolean) => css`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  > * {
    padding: ${tokens.spacingPx.medium};
  }

  > *:first-child {
    padding-right: 0;
  }

  a {
    text-decoration: none;
    color: ${tokens.colors.chalk};
  }

  @media (min-width: 1025px) {
    border: 1px solid rgba(216, 216, 216, 0.2);

    ${isMinimized &&
    css`
      animation: ${animations.flex} ${NAV_TRANSITION_DURATION} forwards;

      /* stylelint-disable-next-line no-duplicate-selectors */
      > *:first-child {
        animation: ${animations.iconSpacing} ${NAV_TRANSITION_DURATION} forwards;
      }
    `}
  }
`;

export const signOutLink = (isMinimized: boolean) => css`
  display: none;

  @media (min-width: 1025px) {
    /* Unchanged properties */
    display: flex;
    justify-content: center;
    flex: 0 0 16px;

    /* Properties changed in .minimize */
    visibility: hidden;
    opacity: 0;
    max-width: 0;
    padding: 0;

    transition-property: opacity, max-width;
    transition-duration: calc(${NAV_TRANSITION_DURATION} / 2);
    transition-delay: calc(${NAV_TRANSITION_DURATION} / 2);

    ${isMinimized &&
    css`
      visibility: visible;
      opacity: 1;
      max-width: initial;
      padding: 16px;

      &:hover,
      &:focus {
        color: ${tokens.colors.concrete};
      }
    `}

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const myProfileLink = css`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    min-width: 0;
    flex: 1;
  }

  @media (min-width: 1025px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    flex: 1;
  }
`;

export const imageContainer = (isMinimized: boolean) => css`
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  border: 1px solid ${tokens.colors.chalk};
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (min-width: 1025px) {
    ${isMinimized &&
    css`
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    `}
  }
`;

export const userInfo = (isMinimized: boolean) => css`
  display: none;

  @media (min-width: 768px) {
    display: block;
    padding: 0 ${tokens.spacingPx.medium};
    overflow: hidden;
    font-size: 1.14rem;
    color: ${tokens.colors.chalk};

    > p:last-child {
      /* Only display user name on tablet breakpoints */
      display: none;
    }
  }

  @media (min-width: 1025px) {
    font-size: ${tokens.typography.sizes.body};

    &:hover,
    &:focus {
      opacity: 0.6;
    }

    p {
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        display: block;
        font-size: 0.6rem;
        color: ${tokens.colors.concrete};
        margin-top: 3px;
      }
    }

    ${isMinimized &&
    css`
      pointer-events: none;
      animation: opacity-anim calc(${NAV_TRANSITION_DURATION} * 0.75) forwards;
    `}
  }
`;
