import { pca } from '../utils/auth';
import { clearAuthTokens } from '../utils/clearAuthTokens';

export default () => {
  return Promise.resolve({
    bootstrap() {
      return Promise.resolve();
    },

    async mount() {
      await clearAuthTokens();
      pca.logoutRedirect();
      window.history.pushState({}, 'DAZN Application Control Center', '/login');
    },

    unmount() {
      return Promise.resolve();
    },
  });
};
