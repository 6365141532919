import { tokens } from '@dazn/acc-sdk-design-system';
import { css } from '@emotion/react';

import { NAV_TRANSITION_DURATION } from './constants';

const navWidth = '200px';

export const base = (open: boolean) => css`
  background-color: ${tokens.colors.tarmac};
  box-sizing: border-box;
  width: 100%;
  position: fixed;

  @media (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80px;
    transition: width ${NAV_TRANSITION_DURATION} cubic-bezier(0.8, 0, 0.2, 1);
    padding: ${tokens.spacingPx.medium} ${tokens.spacingPx.large};
    position: initial;
    ${open &&
    css`
      width: calc(${navWidth} + ${tokens.spacingPx.large} * 2);
    `}
  }
`;

export const navList = (open: boolean) => css`
  background-color: inherit;
  box-sizing: border-box;
  list-style: none;
  position: fixed;

  width: 100%;
  overflow-y: auto;

  padding: ${tokens.spacingPx.xlarge};
  margin: 0;
  left: 0;
  top: 60px;
  bottom: 0;

  transform: translateX(${open ? '0' : '-100%'});
  transition-property: transform, width;
  transition-duration: ${NAV_TRANSITION_DURATION};
  transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1);

  p {
    color: ${tokens.colors.chalk};
    padding: ${tokens.spacingPx.medium} 0 12px;
    overflow: hidden;
    margin: 0;
    font-size: 1.285rem;
    border-top: 1px solid rgba(216, 216, 216, 0.2);
    font-weight: bold;
  }

  @media (min-width: 480px) {
    width: 330px;
  }

  @media (min-width: 768px) {
    top: 78px;
    width: 384px;
  }

  @media (min-width: 1025px) {
    width: ${navWidth};
    opacity: 0;
    visibility: hidden;
    transition: all ${NAV_TRANSITION_DURATION} cubic-bezier(0.8, 0, 0.2, 1);
    margin: 0;
    position: initial;
    overflow: initial;

    p {
      font-size: 1.125rem;
    }

    ${open &&
    css`
      width: ${navWidth};
      padding: 0;
      opacity: 1;
      visibility: visible;
    `}
  }
`;

export const utilWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding: 0 ${tokens.spacingPx.xlarge};

  @media (min-width: 768px) {
    height: 78px;
  }

  @media (min-width: 1025px) {
    display: block;
    height: auto;
    padding: 0;
    margin-bottom: 24px;
  }
`;

export const logo = css`
  color: ${tokens.colors.chalk};
  width: 2em;
  height: 2em;
  margin-left: ${tokens.spacingPx.medium};

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 3em;
    height: 3em;
  }

  @media (min-width: 1025px) {
    margin: ${tokens.spacingPx.large} auto 0;
  }
`;

export const menuIcon = (open: boolean) => css`
  color: ${tokens.colors.chalk};
  height: ${tokens.spacingPx.xlarge};
  width: ${tokens.spacingPx.xlarge};
  padding: 0;
  background: transparent;
  border: none;
  outline: none;

  @media (min-width: 1025px) {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  div {
    height: 2px;
    border-radius: 10px;
    background-color: ${tokens.colors.chalk};
    transition-property: transform, opacity;
    transition-duration: ${NAV_TRANSITION_DURATION};
    transform-origin: 0 50%;
  }

  div:nth-child(1) {
    ${open &&
    css`
      transform: translateY(-50%) rotate(45deg);
    `}
  }

  div:nth-child(2) {
    margin-top: 4px;
    margin-bottom: 4px;

    ${open &&
    css`
      opacity: 0;
    `}
  }

  div:nth-child(3) {
    ${open &&
    css`
      transform: translateY(50%) rotate(-45deg);
    `}
  }
`;

export const titleArea = (open: boolean) => css`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    position: relative;
    left: auto;
    right: 0;
    overflow: visible;
    color: ${tokens.colors.concrete};
    text-align: center;
    font-size: 0.8rem;
    height: 1rem;
    margin: ${tokens.spacingPx.medium} 0;

    span {
      position: absolute;
      right: 0;
      display: block;
      min-width: ${navWidth};
      transition: opacity ${NAV_TRANSITION_DURATION};
      pointer-events: none;
      ${open ? `opacity: 1;` : `opacity: 0;`}
    }
  }

  button {
    position: absolute;
    left: auto;
    right: -35px;
    top: -10px;
    background-color: ${tokens.colors.chalk};
    color: ${tokens.colors.tarmac};
    width: 38px;
    height: 38px;
    border-style: none;
    padding: ${tokens.spacingPx.small};
    margin: 0;
    margin-bottom: ${tokens.spacingPx.medium};
    border-radius: 50%;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);

    @media (min-width: 1440px) {
      display: none;
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      outline: none;
      box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.3);
    }

    svg {
      transition: transform ${NAV_TRANSITION_DURATION} ease;
      transform: ${open ? 'rotate(0deg)' : 'rotate(-180deg)'};
      height: 100%;
    }
  }
`;

export const accountOptionsWrapper = css`
  margin-left: auto;

  @media (min-width: 1025px) {
    margin-top: 8px;
  }
`;

export const signOutLink = css`
  border-color: rgba(216, 216, 216, 0.2);
  border-style: solid;
  border-width: 1px 0 1px;

  margin-top: 8px;
  padding-bottom: 14px;
  padding-top: ${tokens.spacingPx.xlarge};

  @media (min-width: 1025px) {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const sectionLinks = css`
  > p:first-of-type {
    margin-top: 8px;
    padding-bottom: 14px;
    padding-top: ${tokens.spacingPx.xlarge};
  }
`;

export const iconWithText = css`
  display: flex;
  align-items: center;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: ${tokens.spacingPx.medium};
  }
`;
