import { css } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

export const surveyQuestion = css`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: ${tokens.fonts.trim};
  margin: 0 0 ${tokens.spacingPx.large} 0;
  font-size: 1rem;
  line-height: 1.5;
  color: ${tokens.colors.tarmac};
`;

export const smallPrint = css`
  margin: -${tokens.spacingPx.medium} 0 ${tokens.spacingPx.medium};
  font-size: 0.813rem;
  color: ${tokens.colors.asphalt};
`;
