import React from 'react';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Lead, Button, tokens } from '@dazn/acc-sdk-design-system';
import { css } from '@emotion/react';

const modalContentButtonWrapperStyle = css`
  text-align: right;
  margin-top: ${tokens.spacingPx.xlarge};

  button + button {
    margin-left: ${tokens.spacingPx.xlarge};
  }
`;

interface NavigationPromptProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  title?: string;
}

const NavigationPrompt: React.FC<NavigationPromptProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Are you sure you want to leave this page?',
}) => (
  <Dialog open={isOpen} onClose={onClose} disableCloseButton={true} scrollType="dialog" fullWidth={false} maxWidth="">
    <DialogHeader>Leaving page!</DialogHeader>

    <DialogBody enableDividers={false}>
      <Lead>{title}</Lead>
      <p>You may lose any unsaved work.</p>
    </DialogBody>

    <DialogFooter css={modalContentButtonWrapperStyle}>
      <Button buttonType="text" onClick={onClose}>
        No
      </Button>

      <Button onClick={onConfirm}>Yes, leave</Button>
    </DialogFooter>
  </Dialog>
);

export default NavigationPrompt;
