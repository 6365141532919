import { css } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

export const styles = (isActive: boolean, level: number) => css`
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${tokens.typography.sizes.body};
    color: ${tokens.colors.concrete};
    text-decoration: none;
    text-overflow: ellipsis;
    padding-bottom: ${tokens.spacingPx.medium};
    overflow: hidden;
    margin: 0;

    ${level === 1 &&
    css`
      font-size: 1.14rem;
    `}

    ${isActive &&
    css`
      color: ${tokens.colors.chalk};
      font-weight: bold;

      &::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 1em;
        left: 12px;
        background-color: ${tokens.colors.neon};
      }
    `}

    @media (min-width: 1025px) {
      font-size: ${tokens.typography.sizes.smallprint};

      ${level === 1 &&
      css`
        font-size: ${tokens.typography.sizes.body};
      `}

      ${isActive &&
      css`
        &::before {
          left: -8px;
        }
      `}
    }
  }
`;
