import type { AppProps, LifeCycles } from 'single-spa';

import type { ModuleEntryConfig } from '../../types';
import * as nr from '../newrelic';
import { jwt, isLoggedIn, hasValidGroups } from './auth';
import { redirectToLogin, redirectToRequestAccess } from './redirect';
import { safelyTrackValidElements } from '../ga';

type ImportFn<P> = (config?: P & AppProps) => Promise<LifeCycles<P>>;

export const createProtectedModule =
  <P, FixPrettier>(importFn: ImportFn<P>): ImportFn<P> =>
  async () => {
    const { mount, unmount, ...rest } = await importFn();

    let hasMounted = false;

    return {
      ...rest,

      mount(props) {
        return Promise.resolve().then(() => {
          const payload = jwt();

          if (!isLoggedIn(payload)) {
            return redirectToLogin();
          }

          const groups = payload?.['https://ldap.dazn.com/groups'] ?? payload?.groups;

          if (!hasValidGroups(groups ?? [])) {
            return redirectToRequestAccess();
          }

          if (Array.isArray(mount)) throw TypeError('We do not support array lifecycles');
          hasMounted = true;
          return mount(props);
        });
      },

      async unmount(props) {
        if (Array.isArray(unmount)) throw TypeError('We do not support array lifecycles');

        if (hasMounted) {
          const res = await unmount(props);
          hasMounted = false;
          return res;
        }
      },
    };
  };

/**
 * Only returns 'declarative' style elements in `document.head`, whose styles are represented in their text content,
 * rather than empty style tags which have a `sheet` property (such as those used by `emotion`).
 */
const getTraditionalStyleElementsInHead = () =>
  Array.from(document.head.getElementsByTagName('style')).filter(styleElement =>
    styleElement.textContent?.includes('{')
  );

const moduleResolver: ImportFn<unknown> = () => {
  return Promise.resolve({
    mount: () => Promise.resolve(),
    unmount: () => Promise.resolve(),
    bootstrap: () => Promise.resolve(),
    update: () => Promise.resolve(),
  });
};

export const createACCModuleWrapper =
  <P extends ModuleEntryConfig>({
    name,
    iframeSrc,
    importFn = moduleResolver,
  }: {
    name: string;
    iframeSrc?: string;
    importFn?: ImportFn<P & { domElement: HTMLDivElement }>;
  }): ImportFn<P> =>
  async () => {
    const loadStart = Date.now();

    const styleElementsAlreadyInHead = new WeakSet<HTMLStyleElement>(getTraditionalStyleElementsInHead());

    const { mount, unmount, bootstrap, update } = await importFn();

    // NOTE: hold an array of all style elements injected into `document.head` on import
    // of this module (i.e. by webpack css-loader or style-loader)
    const styleElementsAddedByModuleImport = getTraditionalStyleElementsInHead().filter(
      styleElement => !styleElementsAlreadyInHead.has(styleElement)
    );

    nr.addToTrace({
      name: `Importing Application ${name}`,
      start: loadStart,
      end: Date.now(),
    });

    let el: HTMLDivElement;

    return {
      bootstrap: props => {
        if (Array.isArray(bootstrap)) throw TypeError('We do not support array lifecycles');
        return bootstrap({ ...props, domElement: el });
      },

      update:
        update &&
        (props => {
          if (Array.isArray(update)) throw TypeError('We do not support array lifecycles');
          return update({ ...props, domElement: el });
        }),

      async mount(props) {
        if (Array.isArray(mount)) throw TypeError('We do not support array lifecycles');

        const mountStart = Date.now();
        el = document.createElement('div');
        el.id = 'single-spa-application';

        if (iframeSrc) {
          const iframe = document.createElement('iframe');
          iframe.src = iframeSrc;
          iframe.setAttribute('style', 'height:100%; width:100%; border: 0;');
          iframe.onload = () => {
            if (iframe.contentWindow && props?.enableClickTracking) {
              iframe.contentWindow.addEventListener(
                'click',
                safelyTrackValidElements({
                  DocumentElement: iframe.contentWindow.window.Element,
                })
              );
            }
          };

          el.appendChild(iframe);
        }

        if (!props?.enableClickTracking) {
          el.classList.add('PII');
        }

        // NOTE: on first mount this is basically a no-op
        styleElementsAddedByModuleImport.forEach(styleEl => document.head.appendChild(styleEl));

        document.getElementById('root')?.appendChild(el);
        await mount({ ...props, domElement: el });
        nr.addToTrace({
          name: `Mounting Application ${name}`,
          start: mountStart,
          end: Date.now(),
        });
      },

      async unmount(props) {
        if (Array.isArray(unmount)) throw TypeError('We do not support array lifecycles');

        const unmountStart = Date.now();

        try {
          return await unmount({ ...props, domElement: el });
        } finally {
          document.getElementById('root')?.removeChild(el);

          // NOTE: we clean up any global styles injected by the module (but they are re-appended in `mount()`, above)
          styleElementsAddedByModuleImport.forEach(styleEl => document.head.removeChild(styleEl));

          nr.addToTrace({
            name: `Unmounting Application ${name}`,
            start: unmountStart,
            end: Date.now(),
          });
        }
      },
    };
  };
