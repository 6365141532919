interface NewRelicTrace {
  name: string;
  start: number;
  end: number;
}

declare global {
  interface Window {
    newrelic?: {
      addToTrace?: (details: NewRelicTrace) => void;
    };
  }
}

export const addToTrace = (details: NewRelicTrace) => window.newrelic?.addToTrace?.(details);
