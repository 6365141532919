import type { Configuration, RedirectRequest } from '@azure/msal-browser';

const AAD_CLIENT_ID = '2aa49bdb-1415-4b8e-8425-805437d56c8c';
const AAD_TENANT_ID = '30459df5-1e53-4d8b-a162-0ad2348546f1';

export const authConfiguration: Configuration = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AAD_TENANT_ID}`,
    redirectUri: '/oauth2/response',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const authRequest: RedirectRequest = {
  scopes: ['User.Read', `api://2aa49bdb-1415-4b8e-8425-805437d56c8c/acc-portal`],
};
