import {
  start,
  addErrorHandler,
  registerApplication,
  checkActivityFunctions,
  getAppStatus,
  LOAD_ERROR,
  AppError,
  RegisterApplicationConfig,
} from 'single-spa';

import type { BaseEnvironmentConfig, ModuleConfig, RuntimeConfig } from '../types';
import { getInitialData } from './services/graphql';
import { subscribeLoadingSpinner } from './utils/spinner';
import {
  getAccModuleApplications,
  getUnprotectedCoreApplications,
  getHomepageApplication,
  getProtectedCoreApplications,
  getNavBar,
  getSurveyWindow,
} from './applications';
import * as logger from './logger';
import { isLoggedIn, jwt, setAuthTokens, hasValidGroups, getAuthResult } from './utils/auth';
import { IS_IFRAME } from './constants';
import navSections from './config/nav-sections.json';
import { redirectUser, redirectToLogin, redirectToRequestAccess } from './utils/redirect';
import { safelyTrackValidElements } from './ga';
import registerAuthWorker from './auth-worker/register';
import initNavigationPrompt from './utils/navigationPrompt';
import { showSurveyWindow } from './utils/showSurveyWindow';

declare global {
  namespace NodeJS {
    interface Global {
      accModuleConfig?: (config: RuntimeConfig) => ModuleConfig;
    }
  }
}

function navigateToError(err: AppError | Error | string) {
  logger.error(err);
  window.history.replaceState({}, document.title, '/error');
}

const noActiveApps = () => checkActivityFunctions(window.location).length === 0;

function handleErrors() {
  addErrorHandler(err => {
    if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      System.delete(System.resolve(err.appOrParcelName));
    } else {
      navigateToError(err);
    }
  });
}

const getConfig = (): BaseEnvironmentConfig | null =>
  JSON.parse(document.getElementById('environment-config')?.innerHTML || 'null');

// NOTE: this is where Portal calls the module config function to find modules and nav links for which the user has
// permissions
const getPermittedModules = async (config: BaseEnvironmentConfig) => {
  const { permissions, isExternalUser } = await getInitialData(config.GRAPHQL_URL);
  const { modules = [], links = [] } = global.accModuleConfig
    ? global.accModuleConfig({
        ...config,
        permissions,
        navSections,
        isExternalUser,
      })
    : {};
  return { permissions, modules, links };
};

async function loggedInSetup({
  config,
  unprotectedCoreApplications,
}: {
  config: BaseEnvironmentConfig;
  unprotectedCoreApplications: RegisterApplicationConfig<BaseEnvironmentConfig>[];
}) {
  try {
    if (typeof global.accModuleConfig !== 'function') {
      throw new Error('Cannot find module config!');
    }

    const accessToken = await getAuthResult();

    if (!accessToken) {
      throw new Error('Token acquisition failed');
    }

    const { permissions, modules, links } = await getPermittedModules(config);

    const accModuleApplications = [getHomepageApplication(), ...getAccModuleApplications({ modules, config })];
    const navBar = getNavBar({ links, applicationsWithNav: accModuleApplications });

    const protectedCoreApplications = getProtectedCoreApplications({
      allRoutedApplications: [...unprotectedCoreApplications, ...accModuleApplications],
      config,
    });

    if (await showSurveyWindow(permissions)) {
      const surveyWindow = getSurveyWindow();
      registerApplication(surveyWindow);
    }

    accModuleApplications.forEach(app => registerApplication(app));
    registerApplication(navBar);
    protectedCoreApplications.forEach(app => registerApplication(app));
  } catch (err) {
    if (err instanceof Error || typeof err === 'string') {
      navigateToError(err);
    }
  }
}

// NOTE: runs on every page refresh
export default async () => {
  window.addEventListener('click', safelyTrackValidElements({ DocumentElement: window.Element }));

  initNavigationPrompt();

  handleErrors();

  const config = getConfig();

  if (!config) {
    throw new Error('Cannot find environment config!');
  }

  await registerAuthWorker(config);

  const unprotectedCoreApplications = getUnprotectedCoreApplications({ config });

  unprotectedCoreApplications.forEach(app => registerApplication(app));

  if ((window.location.pathname === '/oauth2/response' || window.location.pathname === '/auth0') && !IS_IFRAME) {
    try {
      const isBot = window.location.pathname === '/auth0';
      await setAuthTokens(isBot);
      redirectUser();
    } catch (e) {
      if (e instanceof Error || typeof e === 'string') {
        navigateToError(e);
      }
    }
  }

  const unsubscribeLoadingSpinner = subscribeLoadingSpinner();

  const payload = jwt();
  const loggedIn = isLoggedIn(payload);
  const groups = payload?.['https://ldap.dazn.com/groups'] ?? payload?.groups;
  const validGroups = loggedIn && hasValidGroups(groups ?? []);

  if (loggedIn && validGroups) {
    await loggedInSetup({ config, unprotectedCoreApplications });
  } else if (loggedIn && !validGroups) {
    redirectToRequestAccess();
  } else if (noActiveApps()) {
    redirectToLogin();
  }

  start({ urlRerouteOnly: true });

  return unsubscribeLoadingSpinner;
};
