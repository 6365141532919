import { ACCESS_TOKEN_KEY } from '../enums';
import { INITIALISE_QUERY, SYSTEM_FLAG_QUERY } from './queries';

type Maybe<T> = null | T;

type GraphQLData<Data> = { data: Maybe<Data> };

export interface PermissionGrant {
  name: string;
}

const getUserPermissions = (
  data: GraphQLData<{
    userManagement: Maybe<{
      currentUser: Maybe<{ permissionGrants: Maybe<PermissionGrant[]> }>;
    }>;
  }>
) => (data?.data?.userManagement?.currentUser?.permissionGrants ?? []).map(perm => perm.name);

const getIsExternalUser = (
  data: GraphQLData<{
    userManagement: Maybe<{ currentUser: { isExternal: boolean } }>;
  }>
) => data?.data?.userManagement?.currentUser?.isExternal ?? true;

const getUserAvatar = (
  data: GraphQLData<{
    userManagement: Maybe<{ currentUser: { avatar: string } }>;
  }>
) => data?.data?.userManagement?.currentUser?.avatar ?? '';

const getSystemFlagEnabled = (data: GraphQLData<{ accSystemFlags: Maybe<{ flags: any[] }> }>) =>
  data?.data?.accSystemFlags?.flags ?? [true];

const fetchWithTimeout = <Options extends RequestInit>(url: string, options: Options, timeout: number) =>
  Promise.race([
    fetch(url, options),
    new Promise<never>((resolve, reject) => setTimeout(() => reject(new Error('Network Timed Out')), timeout)),
  ]);

interface Config {
  [key: string]: string;
}

const avatar: Config[] = [];

export const getConfig = (): Config =>
  avatar.reduce((out, nextVersion) => Object.assign(out, nextVersion), {} as Config);

export const updateConfig = (newConfig: Config): void => {
  avatar.push(newConfig);
};

export const getInitialData = async (url: string) => {
  const data = await fetchWithTimeout(
    url,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        operationName: 'getPortalInitialInformation',
        query: INITIALISE_QUERY,
      }),
    },
    5000
  ).then((res: Response) => res.json());

  const avatarString = getUserAvatar(data);
  updateConfig({
    avatar: avatarString,
  });

  return {
    permissions: getUserPermissions(data),
    isExternalUser: getIsExternalUser(data),
  };
};

export const getSystemFlag = async (url: string) => {
  const data = await fetchWithTimeout(
    url,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        operationName: 'getAllFlags',
        query: SYSTEM_FLAG_QUERY,
      }),
    },
    5000
  ).then((res: Response) => res.json());

  return {
    systemFlagsEnabledData: getSystemFlagEnabled(data),
  };
};
