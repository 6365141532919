import React, { useState, useEffect } from 'react';

import { styles } from './styles';
import { Link } from '../Link';

interface NavLinkProps {
  href: string;
  /**
   * Whether or not link active styling should use exact matching
   */
  exact?: boolean;
  /**
   * Nav menu link hierarchy level
   */
  level?: number;
  /**
   * If `true`, will always show a confirmation dialog to the user whether or not
   * the current module has communicated to Portal that it should do so.
   * @default false
   */
  alwaysConfirmNavigation?: boolean;
  confirmNavigationTitle?: string;
  /**
   * Called after a successful navigation.
   */
  onNavigate?: VoidFunction;
}

const levels = new Set([1, 2]);

export const NavLink: React.FC<NavLinkProps> = ({
  href,
  exact = false,
  level = 2,
  onNavigate,
  alwaysConfirmNavigation,
  confirmNavigationTitle,
  children,
}) => {
  if (!levels.has(level)) {
    throw new Error(`Nav link level attribute only accepts one of the following values: ${[...levels].join(', ')}`);
  }

  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => setCurrentPath(window.location.pathname);
    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  const active = exact ? currentPath === href : currentPath.startsWith(href);

  return (
    <div css={styles(active, level)}>
      <Link
        data-testid={`acc-nav-link:${href}`}
        href={href}
        onNavigate={onNavigate}
        alwaysConfirmNavigation={alwaysConfirmNavigation}
        confirmNavigationTitle={confirmNavigationTitle}
      >
        {children}
      </Link>
    </div>
  );
};
