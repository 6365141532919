import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { Notice, tokens } from '@dazn/acc-sdk-design-system';

import { backgroundStyle } from './login';
import { createReactParcel } from '../utils/createReactParcel';
import { AccessDialog } from '../components/access-dialog';
import { clearAuthTokens } from '../utils/clearAuthTokens';

const noticeStyles = css`
  margin: 0 ${tokens.spacingPx.medium} ${tokens.spacingPx.medium};
`;

export const RequestAccessComponent: React.FC<{ cdnUrl: string }> = ({ cdnUrl }) => {
  useEffect(() => {
    clearAuthTokens();
  }, []);

  return (
    <div css={backgroundStyle(`${cdnUrl}/images/f1-pitstop.jpg`)}>
      <AccessDialog
        // url="https://performgroup.service-now.com/sp/?id=sc_cat_item&sys_id=1885e3b1db16c810b6c79407db961977"
        buttonText="Request Access"
      >
        <Notice.Error css={noticeStyles}>You do not have permission to use the ACC</Notice.Error>
      </AccessDialog>
    </div>
  );
};

export const requestAccess = createReactParcel({
  // @ts-expect-error single-spa-react types are incorrect (no params expected)
  loadRootComponent: ({ CDN_URL }) => {
    const Component: React.FC = () => <RequestAccessComponent cdnUrl={CDN_URL} />;

    return Promise.resolve(Component);
  },
});
