import React from 'react';
import { Button, HeadingEcho, Modal, Section, icons } from '@dazn/acc-sdk-design-system';
import { FeedbackIcon } from './components/feedback-icon';
import { Question } from './components/surveyQuestion';
import { SurveyButtons } from './components/buttons';
import { SurveyAnswers } from './components/surveyAnswers';
import { handler } from './handler';
import * as styles from './styles';

export const SurveyBlock: React.FC = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [stage, setStage] = React.useState(1);
  const [score, setScore] = React.useState<number>();
  const [feedback, setfeedback] = React.useState<string>();
  const [completed, setCompleted] = React.useState(false);

  const handleSubmit = async () => {
    await handler({ score, feedback });
    setCompleted(true);
  };

  if (completed) {
    return null;
  }

  return (
    (!isModalOpen && (
      <Button
        aria-label="surveyToggle"
        className="surveyToggle"
        css={styles.surveyOpenBtn}
        onClick={() => setModalOpen(!isModalOpen)}
      >
        {<FeedbackIcon css={styles.surveySVG} />}
      </Button>
    )) || (
      <Modal
        css={styles.surveyModal}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        disableBackgroundClickClose={true}
        disableBodyLock={true}
        renderInline={true}
      >
        <div>
          <HeadingEcho css={styles.surveyHeader.title}>Share your ACC experience</HeadingEcho>
          <Button
            aria-label={'survey-btn-close'}
            buttonType="text"
            css={styles.surveyHeader.close}
            onClick={() => setModalOpen(false)}
          >
            <icons.Cross />
          </Button>
        </div>
        <Question surveyState={{ stage, score }} />
        <Section aria-label="score-section" css={styles.surveySection}>
          <SurveyAnswers surveyState={{ stage, score, feedback }} onSetScore={setScore} onSetFeedback={setfeedback} />
        </Section>
        <SurveyButtons surveyState={{ stage, score, feedback }} onNavigate={setStage} onSubmit={handleSubmit} />
      </Modal>
    )
  );
};
