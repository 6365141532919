import * as logger from '../../logger';
import { ACCESS_TOKEN_KEY, SURVEY_COMPLETE_KEY } from '../../enums';
import { getAccApiUrl } from '../../utils/getAccApiUrl';
import { Payload, SurveyCreatedBody } from '../../../types/survey';

export const handler = async (payload: Payload) => {
  try {
    const response = await fetch(`${getAccApiUrl()}/acc-survey`, {
      body: JSON.stringify(payload),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        'Content-Type': 'application/json',
      },
    });

    const { nextDue } = (await response.json()) as SurveyCreatedBody;
    localStorage.setItem(SURVEY_COMPLETE_KEY, nextDue);
  } catch (error) {
    logger.error((error as Error).message);
    window.history.replaceState({}, document.title, '/error');
  }
};
