import { tokens } from '@dazn/acc-sdk-design-system';
import { css } from '@emotion/react';

export const container = css`
  margin-top: 32px;
  display: grid;
  justify-items: center;
  background-color: white;
  padding-top: 16px;

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const title = css`
  padding: 0 16px;
  font-size: ${tokens.typography.sizes.lead};
  text-align: center;

  &:first-of-type {
    margin: 18px 0;
  }
`;

export const button = css`
  width: 100%;
  padding: 12px 32px;
  font-family: ${tokens.fonts.trim};
  font-weight: bold;
  font-size: ${tokens.typography.sizes.body};
  line-height: 1.5;
  color: ${tokens.colors.tarmac};
  background-color: ${tokens.colors.neon};

  &:hover,
  &:focus {
    background-color: ${tokens.colors.yellowDark20};
  }
`;
