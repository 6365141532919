import type { Configuration, RedirectRequest } from '@azure/msal-browser';
import { REDIRECT_URI } from '../enums';

const { hostname } = window.location;

const azure = {
  clientId: {
    // localhost: '2aa49bdb-1415-4b8e-8425-805437d56c8c', //dev
    localhost: '9e400b8a-9016-4d24-9544-1c92b98a8490', //stage
    // localhost: '2aa49bdb-1415-4b8e-8425-805437d56c8c', //prod
    'acc.dazn-dev.com': '2aa49bdb-1415-4b8e-8425-805437d56c8c',
    'acc.dazn-stage.com': '9e400b8a-9016-4d24-9544-1c92b98a8490',
    'acc.indazn.com': '0430baa5-ab85-45ac-b73e-9e0837719ce5',
  } as Record<string, string>,
  tenantId: {
    // localhost: '30459df5-1e53-4d8b-a162-0ad2348546f1',//dev
    localhost: '30459df5-1e53-4d8b-a162-0ad2348546f1', //stage
    // localhost: '30459df5-1e53-4d8b-a162-0ad2348546f1',//prod
    'acc.dazn-dev.com': '30459df5-1e53-4d8b-a162-0ad2348546f1',
    'acc.dazn-stage.com': '30459df5-1e53-4d8b-a162-0ad2348546f1',
    'acc.indazn.com': '30459df5-1e53-4d8b-a162-0ad2348546f1',
  } as Record<string, string>,
};

const AAD_CLIENT_ID = azure.clientId[hostname];
const AAD_TENANT_ID = azure.tenantId[hostname];

export const authConfiguration: Configuration = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AAD_TENANT_ID}`,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const authRequest: RedirectRequest = {
  scopes: ['User.Read', `api://${AAD_CLIENT_ID}/acc-portal`],
};
