import { Env } from '../types';

export const ID_TOKEN_KEY = 'acc_id_token';
export const ACCESS_TOKEN_KEY = 'acc_access_token';
export const REDIRECT_KEY = 'acc_redirect_to';
export const NONCE_KEY = 'nonce';
export const SURVEY_COMPLETE_KEY = 'acc_nps_survey_completed';
export const ACC_GROUPS = ['app_ACC_users', 'app_ACC_admins', 'aad_app_acc-users'];

export const URL_PATHS_FROM_ENV: Record<Env, string> = {
  [Env.Dev]: 'dazn-dev',
  [Env.Stage]: 'dazn-stage',
  [Env.Production]: 'indazn',
};

export const REDIRECT_URI = '/azure/response';
export const BOT_REDIRECT_URI = '/bot/azure/response';
