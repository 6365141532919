import type { BaseEnvironmentConfig } from '../../types';
import type { ClientMessage } from '../../auth-worker/types';
import { sendExternalsToAuthWorker } from './externals';

export default async function (config: BaseEnvironmentConfig) {
  await navigator.serviceWorker.register('/auth-worker.min.js');

  sendExternalsToAuthWorker({
    GRAPHQL_URL: config.GRAPHQL_URL,
  });

  navigator.serviceWorker.addEventListener('message', ({ data }: { data: unknown }) => {
    if (data && typeof data === 'object' && 'type' in data) {
      const clientMessage = data as ClientMessage;

      switch (clientMessage.type) {
        case 'navigate':
          return window.location.assign(clientMessage.message);

        default:
          throw TypeError('Unrecognized message type');
      }
    }
  });
}
