import { css } from '@emotion/react';
import { tokens, mixins } from '@dazn/acc-sdk-design-system';

export const surveyOpenBtn = css`
  position: fixed;
  right: -14px;
  top: 60%;
  z-index: 99;
  height: 44px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  background-color: rgb(0, 102, 255);

  &:hover {
    background-color: rgb(0, 102, 255);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  }
`;

export const surveySVG = css`
  width: 2rem;
  height: 2rem;
  margin-top: 5px;
`;

export const surveyModal = css`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: ${tokens.spacingPx.xlarge};
  top: 250px;
  z-index: 1;
  width: 320px;
  height: 510px;
  margin: 0;
  padding: 10px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.5px rgba(0, 0, 0, 0.05);
  background-color: ${tokens.colors.white};
`;

export const surveySection = css`
  flex: 1 0 auto;
  background-color: ${tokens.colors.white};
  border: none;
  padding: 0;
`;

export const surveyHeader = {
  title: css`
    display: inline-block;
    padding-right: 10px;
    font: ${mixins.typography.headingEcho};
    font-family: ${tokens.fonts.trim};
    font-size: 1.125rem;
    color: ${tokens.colors.tarmac};
    margin: 0 0 5px 0;
  `,
  close: css`
    display: inline-block;
    font-size: 1rem;
    padding-bottom: 5px;
    margin: 0 0 5px 0;
  `,
};
