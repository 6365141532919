import { BaseEnvironmentConfig } from '../../types';

export const getAccApiUrl = (): string => {
  const getConfig: BaseEnvironmentConfig | null = JSON.parse(
    document.getElementById('environment-config')?.innerHTML || 'null'
  );

  if (getConfig?.SURVEY_URL) {
    return getConfig.SURVEY_URL;
  }

  if (!getConfig || !getConfig.ACC_URL) {
    throw new Error('Failed to get ACC URL');
  }

  const accUrl = getConfig.ACC_URL.replace('https://', '');

  return `https://api.${accUrl}`;
};
