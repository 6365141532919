import React from 'react';
import { Lead, Smallprint } from '@dazn/acc-sdk-design-system';
import surveyData from '../../nps_files/survey.json';
import * as styles from './styles';

interface Question {
  surveyState: {
    stage: number;
    score?: number;
  };
}

export const Question: React.FC<Question> = ({ surveyState }) => {
  const { stage, score } = surveyState;

  const getQuestionText = (): string => {
    if (stage === 1 || (!score && score !== 0)) {
      return surveyData.questions.lead;
    }

    if (score <= surveyData.thresholds.detractor) {
      return surveyData.questions.detractor;
    }

    if (score >= surveyData.thresholds.promoter) {
      return surveyData.questions.promoter;
    }

    return surveyData.questions.passive;
  };

  return (
    <>
      <Lead css={styles.surveyQuestion}>{getQuestionText()}</Lead>
      {stage !== 1 && (
        <Smallprint css={styles.smallPrint}>
          Please don&apos;t include personal or company information (including names) in your response.
        </Smallprint>
      )}
    </>
  );
};
