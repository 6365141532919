import { jwtDecode } from 'jwt-decode';
import localforage from 'localforage';

import { JWTPayload, Env } from '../../types';
import { ACC_GROUPS, ID_TOKEN_KEY, ACCESS_TOKEN_KEY, URL_PATHS_FROM_ENV } from '../enums';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { authConfiguration, authRequest } from './authConfig';

export const jwt = (key = ID_TOKEN_KEY): JWTPayload | null => {
  const token = localStorage.getItem(key);
  return (token && jwtDecode(token)) || null;
};

export const hasValidGroups = (groups: string[] = []) =>
  ACC_GROUPS.some(
    group =>
      groups.includes(group) ||
      groups.some(g => g.toLowerCase().includes('dazn') || g.startsWith('aad_') || g.startsWith('app_'))
  );

export const isLoggedIn = (payload: JWTPayload | null) =>
  Boolean(payload && payload.exp > Math.floor(Date.now() / 1000));

const randomString = (length: number) => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';

  const bytes = new Uint8Array(length);
  const random = window.crypto.getRandomValues(bytes);

  let result = '';
  random.forEach(c => {
    result += charset[c % charset.length];
  });
  return result;
};

const generateNonce = () => randomString(16);

interface LoginConfig {
  auth0ClientId: string;
  env: Env;
  prompt?: string;
  auth0Connection: string;
}

const loginQuery = ({ auth0ClientId, env, prompt, auth0Connection }: LoginConfig) => {
  const authFlow = new URLSearchParams(window.location.search).get('authFlow');

  return {
    /* eslint-disable camelcase */
    client_id: auth0ClientId,
    response_type: authFlow === 'code' ? authFlow : 'id_token token',
    redirect_uri: `${window.location.origin}/azure/response`,
    /* eslint-enable camelcase */

    scope: 'openid profile email groups',
    connection: auth0Connection,
    nonce: generateNonce(),
    audience: `https://acc-api.${URL_PATHS_FROM_ENV[env]}.com`,
    ...(prompt ? { prompt } : {}),
  };
};

export const buildLoginUrl = ({ auth0ClientId, env, prompt, auth0Connection }: LoginConfig) => {
  const queryObj = loginQuery({ auth0ClientId, env, prompt, auth0Connection });
  return {
    url: `https://dazn.eu.auth0.com/authorize?${new URLSearchParams(queryObj)}`,
    nonce: queryObj.nonce,
  };
};

export const pca = (() => {
  // if ('vijay' === 'test') return {} as PublicClientApplication;

  return new PublicClientApplication(authConfiguration);
})();

export const getAuthResult = async () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY) || '';

  const isBot = token.startsWith('bot');

  if (isBot) return isBot;

  await pca.initialize();

  await pca.handleRedirectPromise();

  const activeAccount = pca.getActiveAccount();
  const accounts = pca.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return null;
  }

  const request = {
    scopes: authRequest.scopes,
    account: activeAccount || accounts[0],
  };

  const authResult = await pca.acquireTokenSilent(request);

  return authResult;
};

export async function setAuthTokens(isBot?: boolean) {
  if (isBot) {
    const { hash } = window.location;
    const [, idToken] = hash.match(/id_token=([^&]+)/i) ?? [];
    const [, accessToken] = hash.match(/access_token=([^&]+)/i) ?? [];
    if (!idToken || !accessToken) throw Error('Unable to parse hash');
    window.localStorage.setItem(ID_TOKEN_KEY, idToken);
    window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    // NOTE: used by the Auth Worker
    await localforage.setItem(ACCESS_TOKEN_KEY, accessToken);
    return;
  }

  await pca.initialize();

  await pca.handleRedirectPromise();

  const activeAccount = pca.getActiveAccount();
  const accounts = pca.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return null;
  }

  const request = {
    scopes: authRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await pca.acquireTokenSilent(request);

    const accessToken = authResult.accessToken;
    const idToken = authResult.idToken;

    window.localStorage.setItem(ID_TOKEN_KEY, idToken);
    window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

    await localforage.setItem(ACCESS_TOKEN_KEY, accessToken);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await pca.acquireTokenRedirect(request);
    }

    throw error;
  }
}
