interface DataLayerEvent {
  event: string;
  'gtm.element': Element;
  ClickPageURL: string;
  ClickText: string | null;
}

declare global {
  interface Window {
    dataLayer?: {
      push: (event: DataLayerEvent) => void;
    };
  }
}

const validTagNames = new Set(['A', 'BUTTON']);

/**
 * Iterate up the DOM to check for a PII class before pushing to GA
 * @param DocumentElement The Element instance for the current document.
 * This is different to the typical window.Element if running within an iFrame.
 */
export const safelyTrackValidElements = ({ DocumentElement }: { DocumentElement: typeof Element }) => (
  e: MouseEvent
) => {
  if (!window.dataLayer || typeof e.composedPath !== 'function') return;

  try {
    let firstValidElement: Element | undefined;

    e.composedPath().forEach(elem => {
      if (elem instanceof DocumentElement) {
        if (!firstValidElement && validTagNames.has(elem.tagName)) {
          firstValidElement = elem;
        }

        if (elem.classList?.contains('PII')) {
          throw new Error();
        }
      }
    });

    if (firstValidElement) {
      window.dataLayer.push({
        event: 'validLinkOrButtonClick',
        'gtm.element': firstValidElement,
        ClickPageURL: `${window.location.origin}${window.location.pathname}`,
        ClickText: firstValidElement.textContent,
      });
    }
  } catch {
    // Do nothing as there could potentially be PII
  }
};
