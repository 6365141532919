import React from 'react';
import { Lifecycles } from 'single-spa-react';

import { createReactParcel } from '../utils/createReactParcel';
import { Nav } from '../components/nav';

const initialParcel = createReactParcel({
  // @ts-expect-error single-spa-react types are incorrect (no params expected)
  loadRootComponent: ({ links }: { links: ModuleLink[] }) => {
    const Component: React.FC = () => <Nav links={links} />;
    return Promise.resolve(Component);
  },
  domElementGetter: () => {
    const navContainer = document.createElement('div');
    navContainer.id = 'acc-nav';
    document.body.insertBefore(navContainer, document.querySelector('main'));

    return navContainer;
  },
});

export const nav: Lifecycles = {
  ...initialParcel,
  unmount: props =>
    initialParcel.unmount(props).then(() => {
      const navContainer = document.querySelector('#acc-nav');

      navContainer?.parentNode?.removeChild(navContainer);
    }),
};
