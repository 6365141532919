import React from 'react';
import * as styles from './styles';

interface Rows {
  name: string;
  id: string;
  value: string;
  checked: boolean;
  onChange?: (event: { target: HTMLInputElement }) => void;
  label: string;
}

export const InputRows: React.FC<Rows> = ({ name, id, value, checked, onChange, label }: Rows) => {
  return (
    <div css={styles.surveyScores.container}>
      <input
        aria-label="input-score"
        css={styles.surveyScores.input}
        name={name}
        id={id}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label css={styles.surveyScores.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
