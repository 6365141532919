const createSpinnerHelpers = (spinner: HTMLElement | null) => {
  let timer: NodeJS.Timeout | null;

  if (!spinner) {
    throw Error('#acc-spinner not found in the document');
  }

  return {
    show: () => {
      if (timer) return;

      timer = setTimeout(() => {
        spinner.classList.add('acc-spinner-active');
        timer = null;
      }, 200);
    },
    hide: () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      spinner.classList.remove('acc-spinner-active');
    },
  };
};

export function subscribeLoadingSpinner() {
  try {
    const spinner = createSpinnerHelpers(document.querySelector('#acc-spinner'));

    const eventHandlers = new Map([
      ['single-spa:before-routing-event', () => spinner.show()],
      ['single-spa:routing-event', () => spinner.hide()],
    ]);

    eventHandlers.forEach((handler, event) => window.addEventListener(event, handler));

    return () => eventHandlers.forEach((handler, event) => window.removeEventListener(event, handler));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
