import React from 'react';
import { css } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

import { Error } from '../components/error';
import { createReactParcel } from '../utils/createReactParcel';

const listStyles = css`
  font-size: ${tokens.typography.sizes.lead};
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: ${tokens.spacingPx.medium};

    &:before {
      content: '+';
      display: inline-block;
      position: absolute;
      width: 25px;
      left: 0;
    }
  }
`;

export const notFound = createReactParcel({
  // @ts-expect-error single-spa-react types are incorrect (no params expected)
  loadRootComponent: ({ CDN_URL }) => {
    const Component: React.FC = () => (
      <Error backgroundUrl={`${CDN_URL}/images/boxing-alvarez-jacobs.jpg`}>
        <div>
          <div>Sorry, the page you requested could not be found.</div>
          <div>This might be because:</div>
          <ul css={listStyles}>
            <li>You have typed the web address incorrectly, or</li>
            <li>the page you were looking for may have been moved, updated or deleted</li>
          </ul>
        </div>
      </Error>
    );

    return Promise.resolve(Component);
  },
});
