import { css } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

export const surveyBtnSolid = css`
  background: ${tokens.colors.tarmac};
  color: ${tokens.colors.white};
`;

export const surveyBtn = css`
  width: 74px;
  height: 42px;
  padding: 13px 18px;
  color: ${tokens.colors.tarmac};
  background: transparent;
  border: solid 2px ${tokens.colors.tarmac};

  &:hover {
    ${surveyBtnSolid}
  }
`;

export const surveyButtonContainer = css`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;
