import neuron from '@dazn/acc-sdk-neuron';

/**
 * Use default prompt to confirm browser navigation.
 */
const promptBeforeNavigate = (e: BeforeUnloadEvent) => {
  // Cancel the event as stated by the standard.
  e.preventDefault();
  // Chrome requires returnValue to be set.
  // eslint-disable-next-line no-param-reassign
  e.returnValue = '';
};

export default function () {
  /**
   * Will trigger on any routing event that involves a change in activity status
   * for any Single-SPA application. We must set `neuron.navigationPrompt` to
   * false in this case so that if a user navigates away from a module via SPA-style
   * navigation (i.e. not therefore refreshing the page), the navigation block
   * is cleaned up. Otherwise one module telling Portal to confirm navigation would
   * affect navigation in all modules until the next full page refresh.
   */
  window.addEventListener('single-spa:before-app-change', () => {
    neuron.navigationPrompt.update(false);
  });

  neuron.navigationPrompt.observe(shouldBlockBrowserNavigation => {
    if (shouldBlockBrowserNavigation) {
      window.addEventListener('beforeunload', promptBeforeNavigate);
    } else {
      window.removeEventListener('beforeunload', promptBeforeNavigate);
    }
  });
}
