/* istanbul ignore file */
// Modified from https://codepen.io/RyanAaronGreen/pen/OzeerJ
export const letItSnow = () => {
  const canvas = document.createElement('canvas');
  canvas.id = 'snow-cavas';
  canvas.setAttribute('width', String(window.innerWidth));
  canvas.setAttribute('height', String(window.innerHeight));
  canvas.setAttribute('style', `position: absolute; pointer-events: none; top: 0;`);
  const canvas2DContext = canvas.getContext('2d');

  // Variables
  const attributes = {
    particleCount: 500, // Change amount of snowflakes
    particleSize: 3, // Max size of a snowflake
    fallingSpeed: 1, // Intensity of the snowfall horizontal
    colors: ['#ccc', '#eee', '#fff', '#ddd'], // Array of usable colors
  };

  // Utility Functions
  function randomIntFromRange(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function randomColor(colors: string[]) {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // Objects
  class Particle {
    initialX: number;
    initialY: number;
    x: number;
    y: number;
    radius: number;
    color: string;
    radians: number;
    velocity: number;

    constructor(x: number, y: number, radius: number, color: string, radians: number) {
      this.initialX = x;
      this.initialY = y;
      this.x = x;
      this.y = y;
      this.radius = radius;
      this.color = color;
      this.radians = radians;
      this.velocity = 0.005;
    }

    update() {
      // Move these points over time
      this.radians += this.velocity;
      this.x = this.initialX + Math.cos(this.radians) * 400;
      this.y = this.initialY + Math.tan(this.radians) * 600;

      this.draw();
    }

    draw() {
      if (canvas2DContext) {
        canvas2DContext.beginPath();
        canvas2DContext.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        canvas2DContext.fillStyle = this.color;
        canvas2DContext.fill();
        canvas2DContext.closePath();
      }
    }
  }

  // Implementation
  let particles: Particle[];

  function init() {
    particles = [];

    for (let i = 0; i < attributes.particleCount; i++) {
      particles.push(
        new Particle(
          Math.random() * canvas.width,
          Math.random() * canvas.height,
          randomIntFromRange(1, attributes.particleSize),
          randomColor(attributes.colors),
          Math.random() * 80
        )
      );
    }
  }

  // Animation Loop
  function animate() {
    if (canvas2DContext) {
      requestAnimationFrame(animate);
      canvas2DContext.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach(particle => {
        particle.update();
      });
    }
  }

  window.addEventListener('resize', () => {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    init();
  });

  init();
  animate();
  document.body.appendChild(canvas);
};
