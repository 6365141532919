import React, { useState, useEffect, createRef } from 'react';
import { icons } from '@dazn/acc-sdk-design-system';

import { AccountOptions } from './components/AccountOptions';
import { Link } from './components/Link';
import { NavLink } from './components/NavLink';
import { DAZNIcon } from '../dazn-icon';
import { ModuleLink, NavSection } from '../../../types';
import * as styles from './styles';

type Link = Omit<ModuleLink, 'section'>;

export interface IntermediateSections {
  [name: string]: NavSection & { links: Link[] };
}

const tabletWidth = 1024;

const { ChevronLeft, Home, Exit } = icons;

const sortLinks = (a: Link, b: Link) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
};

const linksToSections = (links: ModuleLink[]) => {
  const sections: IntermediateSections = {
    // NOTE: this is a fallback section, hopefully only used when
    // a local-dev module-config refers to a non-existent section
    Other: {
      name: 'Other',
      order: Infinity,
      links: [],
    },
  };

  links.forEach(link => {
    const { section, ...linkData } = link;
    const sectionName = section?.name ?? 'Other';

    sections[sectionName] = sections[sectionName] ?? { ...section, links: [] };
    sections[sectionName].links.push(linkData);
  });

  return Object.values(sections)
    .map(section => ({ ...section, links: section.links.sort(sortLinks) }))
    .sort((a, b) => a.order - b.order);
};

interface NavProps {
  links: ModuleLink[];
}

export const Nav: React.FC<NavProps> = ({ links = [] }) => {
  const [open, setOpen] = useState(window.innerWidth > tabletWidth);
  const sections = linksToSections(links);
  const resizeTimer = React.useRef(0);
  const navRef = createRef<HTMLDivElement>();

  const handleResize = () => {
    clearTimeout(resizeTimer.current);
    resizeTimer.current = window.setTimeout(() => {
      if (window.innerWidth > tabletWidth) {
        setOpen(true);
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Close on clicks outside nav when on tablet
    const handleClickOutside = (event: MouseEvent) => {
      if (window.innerWidth <= tabletWidth && navRef.current && !navRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [navRef]);

  const onNavigate = () => {
    // Auto close nav on tablet/mobile
    if (window.innerWidth <= tabletWidth) {
      setOpen(false);
    }
  };

  const handleClick = () => setOpen(prev => !prev);

  return (
    <nav css={styles.base(open)} aria-expanded={open} ref={navRef}>
      <div css={styles.utilWrapper}>
        <button css={styles.menuIcon(open)} onClick={handleClick} aria-label="Toggle the navigation menu">
          <div />
          <div />
          <div />
        </button>
        <div css={styles.logo}>
          <DAZNIcon />
        </div>

        <p css={styles.titleArea(open)}>
          <span>Application Control Centre</span>
          <button onClick={handleClick} aria-label="Toggle the navigation menu">
            <ChevronLeft />
          </button>
        </p>

        <div css={styles.accountOptionsWrapper}>
          <AccountOptions minimize={!open} />
        </div>
      </div>

      <ul css={styles.navList(open)}>
        <li>
          <NavLink exact href="/" onNavigate={onNavigate} level={1}>
            <span css={styles.iconWithText}>
              <Home />
              Home
            </span>
          </NavLink>
        </li>

        {sections
          .filter(({ links: SectionLinks }) => (SectionLinks?.length ?? 0) > 0)
          .map(({ name, links: SectionLinks }) => (
            <li css={styles.sectionLinks} key={name}>
              <p>{name}</p>
              {SectionLinks.map(link => (
                <NavLink key={link.path} exact={Boolean(link.exact)} href={link.path} onNavigate={onNavigate}>
                  {link.name}
                </NavLink>
              ))}
            </li>
          ))}
        <li css={styles.signOutLink}>
          <NavLink
            exact
            href="/logout"
            level={1}
            alwaysConfirmNavigation
            confirmNavigationTitle="Are you sure you want to log out?"
          >
            <span css={styles.iconWithText}>
              <Exit />
              Log out
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
