export default () =>
  Promise.resolve({
    bootstrap() {
      return Promise.resolve();
    },

    mount() {
      return Promise.resolve();
    },

    unmount() {
      return Promise.resolve();
    },
  });
