import { pca } from '../utils/auth';
import { clearAuthTokens } from '../utils/clearAuthTokens';
import { botValidation } from './login';

export default () => {
  return Promise.resolve({
    bootstrap() {
      return Promise.resolve();
    },

    async mount() {
      const isBot = botValidation();

      await clearAuthTokens();
      if (!isBot) pca.logoutRedirect();

      window.history.pushState({}, 'DAZN Application Control Center', '/login');
    },

    unmount() {
      return Promise.resolve();
    },
  });
};
