import { SurveyStatusBody } from '../../types/survey';
import { ACC_SURVEY_VIEW_PERMISSION } from '../constants';
import { ACCESS_TOKEN_KEY, SURVEY_COMPLETE_KEY } from '../enums';
import { getAccApiUrl } from './getAccApiUrl';

const localSurveyCompleteFlagSet = () => {
  const localSurveyCompleteFlag = localStorage.getItem(SURVEY_COMPLETE_KEY);

  if (!localSurveyCompleteFlag) {
    return false;
  }

  if (new Date(localSurveyCompleteFlag).getTime() < Date.now()) {
    localStorage.removeItem(SURVEY_COMPLETE_KEY);

    return false;
  }

  return true;
};

const checkUserSurveyStatus = async () => {
  try {
    const response = await fetch(`${getAccApiUrl()}/acc-survey/status`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
      },
    });

    const { completed, nextDue } = (await response.json()) as SurveyStatusBody;

    if (!completed) {
      return false;
    }

    if (nextDue && new Date(nextDue).getTime() > Date.now()) {
      localStorage.setItem(SURVEY_COMPLETE_KEY, nextDue);

      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const showSurveyWindow = async (permissions: string[]) => {
  const hasSurveyPermission = permissions.includes(ACC_SURVEY_VIEW_PERMISSION);

  // Check survey is enabled
  if (!hasSurveyPermission) {
    return false;
  }

  // Check if user has local storage for previous completion
  if (localSurveyCompleteFlagSet()) {
    return false;
  }

  // Check remote if user has previous completion
  if (await checkUserSurveyStatus()) {
    return false;
  }

  return true;
};
