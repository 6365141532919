import { css } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

export const surveyScores = {
  container: css`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 8px 0;

    &:last-of-type {
      margin: 0;
    }
  `,
  label: css`
    font-family: ${tokens.fonts.trim};
    font-size: 0.875rem;
    color: ${tokens.colors.tarmac};
  `,
  input: css`
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-left: 6px;
  `,
};
