/** @jsx jsx */

import React, { useState, useEffect, createRef } from 'react';
import { Chevron, icons } from '@dazn/acc-sdk-design-system';
import { jsx } from '@emotion/react';
import { FaThumbtack } from 'react-icons/fa';

import { AccountOptions } from './components/AccountOptions';
import { Link } from './components/Link';
import { NavLink } from './components/NavLink';
import { DAZNIcon } from '../dazn-icon';
import { ModuleLink, NavSection } from '../../../types';
import * as styles from './styles';

type Link = Omit<ModuleLink, 'section'>;

export interface IntermediateSections {
  [name: string]: NavSection & { links: Link[] };
}

const tabletWidth = 1024;

const { Home, Exit } = icons;

const sortLinks = (a: Link, b: Link) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
};

const linksToSections = (links: ModuleLink[]) => {
  const sections: IntermediateSections = {
    // NOTE: this is a fallback section, hopefully only used when
    // a local-dev module-config refers to a non-existent section
    Other: {
      name: 'Other',
      order: Infinity,
      links: [],
    },
  };

  links.forEach(link => {
    const { section, ...linkData } = link;
    const sectionName = section?.name ?? 'Other';

    sections[sectionName] = sections[sectionName] ?? { ...section, links: [] };
    sections[sectionName].links.push(linkData);
  });

  return Object.values(sections)
    .map(section => ({ ...section, links: section.links.sort(sortLinks) }))
    .sort((a, b) => a.order - b.order);
};

interface NavProps {
  links: ModuleLink[];
}

export const Nav: React.FC<NavProps> = ({ links = [] }) => {
  const [open, setOpen] = useState(JSON.parse(localStorage.getItem('navOpen') || 'false') ?? false);
  const [toggle, setToggle] = useState(JSON.parse(localStorage.getItem('navToggle') || 'false') ?? false);
  const [pinToggle, setPinToggle] = useState(location.pathname !== '/');
  const [pathName, setPathName] = useState(location.pathname);

  const sections = linksToSections(links);
  const resizeTimer = React.useRef(0);
  const navRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setPathName(location.pathname);
    if (location.pathname === '/') {
      setOpen(true);
      setPinToggle(false);
    } else {
      setPinToggle(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem('navOpen', String(open));
    localStorage.setItem('navToggle', String(toggle));
  }, [open, toggle]);

  const handleResize = () => {
    clearTimeout(resizeTimer.current);
    resizeTimer.current = window.setTimeout(() => {
      if (window.innerWidth > tabletWidth && location.pathname !== '/') {
        setOpen(true);
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        window.innerWidth <= tabletWidth &&
        navRef.current &&
        !navRef.current.contains(event.target as Node) &&
        location.pathname !== '/'
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [navRef, location.pathname]);

  const togglePin = () => {
    if (location.pathname !== '/') {
      setToggle(!toggle);
      if (toggle) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  };

  const navigate = () => {
    setPathName(location.pathname);
    if (toggle && location.pathname !== '/') {
      setOpen(true);
    } else if (!toggle && location.pathname !== '/') {
      setOpen(false);
    }
  };

  return (
    <nav css={styles.base(open)} aria-expanded={open} ref={navRef}>
      <div css={styles.utilWrapper}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: pinToggle ? 'row' : 'row-reverse',
          }}
        >
          {open && pinToggle ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: '10px',
                  position: 'relative',
                }}
              >
                <FaThumbtack
                  onClick={togglePin}
                  style={{
                    color: 'white',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                    transform: toggle ? 'rotate(0deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
                {!toggle && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: '120%',
                      height: '2px',
                      backgroundColor: 'red',
                      transform: 'translate(-50%, -50%) rotate(-45deg)',
                    }}
                  />
                )}
                <span style={{ color: 'white', fontSize: '1rem', marginTop: '5px' }}></span>
              </div>

              <span>
                <Chevron
                  onClick={() => setOpen(false)}
                  type={'left'}
                  style={{
                    margin: '10px',
                    color: 'white',
                    position: 'absolute',
                    top: '0',
                    right: '-5px',
                  }}
                />
              </span>
            </>
          ) : (
            <span>
              <Chevron onClick={() => setOpen(true)} type={'right'} style={{ margin: '10px', color: 'white' }} />
            </span>
          )}
        </div>
        <div css={styles.logo}>
          <DAZNIcon />
        </div>

        <p css={styles.titleArea(open)}>
          <span>Application Control Centre</span>
        </p>

        <div css={styles.accountOptionsWrapper}>
          <AccountOptions minimize={!open} />
        </div>
      </div>

      <ul css={styles.navList(open)} onClick={navigate}>
        <li>
          <NavLink exact href="/" level={1}>
            <span css={styles.iconWithText}>
              <Home />
              Home
            </span>
          </NavLink>
        </li>

        {sections
          .filter(({ links: SectionLinks }) => (SectionLinks?.length ?? 0) > 0)
          .map(({ name, links: SectionLinks }) => (
            <li css={styles.sectionLinks} key={name}>
              <p>{name}</p>
              {SectionLinks.map(link => (
                <NavLink key={link.path} exact={Boolean(link.exact)} href={link.path}>
                  {link.name}
                </NavLink>
              ))}
            </li>
          ))}

        <li css={styles.signOutLink}>
          <NavLink
            exact
            href="/logout"
            level={1}
            alwaysConfirmNavigation
            confirmNavigationTitle="Are you sure you want to log out?"
          >
            <span css={styles.iconWithText}>
              <Exit />
              Log out
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
