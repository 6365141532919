import { css } from '@emotion/react';
import { tokens } from '@dazn/acc-sdk-design-system';

export const surveyFeedback = css`
  height: 100%;

  > div {
    height: 90%;
    background-color: ${tokens.colors.white};
    box-shadow: 0 2px 0 ${tokens.colors.iron};
  }

  textarea {
    resize: none;
  }
`;
