import React from 'react';

import { Error } from '../components/error';
import { createReactParcel } from '../utils/createReactParcel';

export const error = createReactParcel({
  // @ts-expect-error single-spa-react types are incorrect (no params expected)
  loadRootComponent: ({ CDN_URL }) => {
    const Component: React.FC = () => (
      <Error backgroundUrl={`${CDN_URL}/images/boxing-alvarez-jacobs.jpg`}>Something went wrong.</Error>
    );

    return Promise.resolve(Component);
  },
});
