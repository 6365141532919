import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact, { Options } from 'single-spa-react';
import { Notice } from '@dazn/acc-sdk-design-system';

import { getRootElement } from './getRootElement';

const ErrorMessage = <Notice.Error>Something went wrong!</Notice.Error>;

export const createReactParcel = ({
  loadRootComponent,
  domElementGetter = getRootElement,
}: Pick<Options, 'loadRootComponent' | 'domElementGetter'>) =>
  singleSpaReact({
    React,
    ReactDOM,
    loadRootComponent,
    domElementGetter,
    // @ts-expect-error single-spa-react types are incorrect (no errorBoundary option)
    errorBoundary: () => ErrorMessage,
  });
