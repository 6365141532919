import React, { useCallback, useEffect, useState } from 'react';
import { Button, HeadingAlpha } from '@dazn/acc-sdk-design-system';
import { useMsal } from '@azure/msal-react';

import { DAZNIcon } from '../dazn-icon';
import * as styles from './styles';
import { authRequest } from '../../utils/authConfig';

interface AccessDialogProps {
  buttonText: string;
  children?: React.ReactNode;
}

const AUTHENTICATION_TIMEOUT = 3_000;

export const AccessDialog: React.FC<AccessDialogProps> = ({ buttonText, children }) => {
  const { instance } = useMsal();

  const [errorMessage, setErrorMessage] = useState<[string, number | string] | null>(null);

  const forceLogin = useCallback(() => {
    setErrorMessage(null);
    instance.loginRedirect(authRequest);
  }, [instance]);

  useEffect(() => {
    if (errorMessage) return;

    const timer = setTimeout(() => {
      setErrorMessage(['Sorry, this is taking longer than expected.', 'internal_timeout']);
    }, AUTHENTICATION_TIMEOUT);

    return () => clearTimeout(timer);
  }, [errorMessage]);

  return (
    <div css={styles.container}>
      <DAZNIcon />
      <HeadingAlpha css={styles.title}>Application Control Centre</HeadingAlpha>
      {children}
      <Button
        css={styles.button}
        as="a"
        // href={url}
        onClick={forceLogin}
      >
        {buttonText}
      </Button>
    </div>
  );
};
